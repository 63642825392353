import ScrollMagic from 'scrollmagic'
import Glide from '@glidejs/glide'
import Helpers from './helpers'

const controllerPathes = {
  common: {
    init: () => {
      document.addEventListener('DOMContentLoaded', function () {
        console.log('common: init')
        Helpers.smContents()
        Helpers.initModals()
      })
    }
  },
  index: {
    init: () => {
      window.addEventListener('load', function () {
        console.log('common: index1')
        const auto = document.getElementById('acf_slider').dataset.auto
        console.log('common: auto:' + auto)
        const params = {
          perview: 3,
          type: 'carousel',
          startAt: 0,
          autoplay: (auto==='on' ? 5000 : 0),
          peek: { before: 0, after: 0 },
          gap: 50,
          breakpoints: {
            960: {
              peek: { before: 90, after: 90 },
            },
            769: {
              perView: 1,
              peek: { before: 0, after: 0 },
            }
          }
        }
        new Glide('.glide', params).mount()
      })
    }
  },
  home: {
    init: () => {
      window.addEventListener('load', function () {
        console.log('common: index1')
        const auto = document.getElementById('acf_slider').dataset.auto
        console.log('common: auto:' + auto)
        const params = {
          perview: 3,
          type: 'carousel',
          startAt: 0,
          autoplay: (auto==='on' ? 5000 : 0),
          peek: { before: 0, after: 0 },
          gap: 50,
          breakpoints: {
            960: {
              peek: { before: 90, after: 90 },
            },
            769: {
              perView: 1,
              peek: { before: 0, after: 0 },
            }
          }
        }
        new Glide('.glide', params).mount()
      })
    }
  },
  kitchensink: {
    init: () => {
      window.addEventListener('load', function () {
        const params = {
          perview: 3,
          type: 'carousel',
          startAt: 0,
          autoPlay: true,
          peek: { before: 90, after: 90 },
          gap: 50,
          breakpoints: {
            769: {
              perView: 1,
              peek: { before: 0, after: 0 },
            }
          }
        }
        new Glide('.glide', params).mount()
      })
    }
  },
  retirement: {
    init: () => {
      window.addEventListener('load', function () {
        const controller = new ScrollMagic.Controller()
        const scene = new ScrollMagic.Scene({
          triggerElement: '.table-wrap',
          triggerHook: 'onLeave'
        })
        scene
          .on('start', function(e) {
            if(e.target.controller().info("scrollDirection") === 'FORWARD') {
              Helpers.loadGuide("table-guide")
            }
          })
          .addTo(controller)
      })
    }
  }
}

export default controllerPathes
