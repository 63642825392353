import controllerPathes from './controller-path'
class controller {
  static init() {
    const bodyClasses = document.body.classList
    fire('common')
    for (let i = 0; i < bodyClasses.length; i++) {
      const cls = bodyClasses[i];
      fire(cls)
    }
  }
}
const fire = (func, funcName, args) => {
  funcName = funcName === undefined ? 'init' : funcName
  if (
    func !== undefined &&
    controllerPathes[func] &&
    typeof controllerPathes[func][funcName] === 'function'
  ) {
    console.info('controller calling: ', func + '.' + funcName)
    controllerPathes[func][funcName](args)
  }
}
export default controller