import settings from './settings'
import ScrollMagic from 'scrollmagic'

if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector || 
    Element.prototype.webkitMatchesSelector;
}

console.log('closest: ', Element.prototype.closest)
if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;

    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

const helpers = {
  loadGuide: (target) => {
    const table = document.getElementById(target)
    const tableitself = table.getElementsByTagName('table')[0]
    const guide = document.createElement('div')
    const img = document.createElement('img')    
    if (
      window.innerWidth > settings.guide.mq.medium ||
      document.getElementById('table-guide__container')
    ) return false
    guide.id = 'table-guide__container'
    img.src = `${g_template_directory_uri}/asset/src/images/icons/icon-scroll.png`
    guide.append(img)
    table.append(guide)

    helpers.fadeIn(guide, 0.3)
    setTimeout(() => {
      img.classList.add('howto')
      tableitself.classList.add('howto')
    }, settings.guide.delayHowto);
    setTimeout(() => {
      helpers.fadeOut(guide, 0.3, () => {
        guide.style.display = 'none'
      })
    }, settings.guide.delayHide);
  },
  fadeIn: (target, duration, callback) => {
    let fadeInt = 0
    let period = 0
    target.style.opacity = 0
    clearInterval(fadeInt)
    fadeInt = setInterval(() => {
      if (period / duration < 1) {
        period += 0.01
      } else if(period / duration >= 1) {
        clearInterval(fadeInt)
        period = duration
        if (typeof callback === 'function') callback()
      }
      target.style.opacity = period / duration
    }, 10);
  },
  fadeOut: (target, duration, callback) => {
    let fadeInt = 0
    let period = 1
    target.style.opacity = 1
    clearInterval(fadeInt)
    fadeInt = setInterval(() => {
      if (period / duration > 0) {
        period -= 0.01
      } else if(period / duration <= 0) {
        clearInterval(fadeInt)
        period = 0
        if (typeof callback === 'function') callback()
      }
      target.style.opacity = period / duration
    }, 10);
  },
  smContents: () => {
    const branding = document.getElementById('branding')
    const container = document.getElementsByClassName('container')[0]
    const floaty = document.getElementsByClassName('e-floaty-email')[0]
    const controller = new ScrollMagic.Controller()
    const sceneNavi = new ScrollMagic.Scene({
      triggerElement: '#branding',
      triggerHook: 0,
      duration: container.offsetHeight + branding.offsetHeight,
      offset: branding.offsetHeight
    })
    const sceneInquiry = new ScrollMagic.Scene({
      triggerElement: '.container',
      triggerHook: 0
    })
    sceneNavi
      .setClassToggle("body", "stick")
      .addTo(controller)
    sceneInquiry
      .on('enter leave', function(e) {
        if (e.type == 'enter') {
          floaty.classList.add('show')
        } else {
          floaty.classList.remove('show')
        }
      })
      .addTo(controller)
  },
  initModals: () => {
    const modalLinks = document.querySelectorAll(".open-modal")
    const closeButtons = document.querySelectorAll(".m-modal-close")
    for (let i = 0; i < modalLinks.length; i++) {
      const element = modalLinks[i];
      element.addEventListener("click", function(e) {
        e.preventDefault()
        const modalId = this.dataset.modal
        document.getElementById(modalId).classList.add("show")
      });
    }
    for (let i = 0; i < closeButtons.length; i++) {
      const element = closeButtons[i];
      element.addEventListener("click", function(e) {
        e.preventDefault()
        e.target.closest(".m-modal").classList.remove("show")
      });
    }
  }
}
export default helpers