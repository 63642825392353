const settings = {
  guide: {
    mq: {
      small: 400,
      medium: 768,
      large: 944
    },
    delayHowto: 300,
    delayHide: 3000
  }
}
export default settings