export default class Loading {
  static load() {

    document.addEventListener("DOMContentLoaded", function() {
      document.body.classList.add('ready');
       // let target = document.getElementById('loading')
       // target.classList.add('loaded');
    });

    window.addEventListener('load', function() {

    })

  }
}
